// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/blocked_icon_orange.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.ticket-system .page-data-table__row-wrapper:hover {\n  opacity: 0.75;\n  cursor: pointer;\n}\n.ticket-system .page-data-table__row-wrapper:focus {\n  outline: auto;\n}\n.ticket-system .page-data-table__cell--unresolved {\n  color: #008489;\n}\n.ticket-system .page-data-table__cell--resolved {\n  color: #34c0cc;\n}\n.ticket-system .page-data-table__cell--pending {\n  color: var(--color-secondary);\n}\n.ticket-system .page-data-tabs__tab--hidden {\n  position: relative;\n}\n.ticket-system .page-data-tabs__tab--hidden::after {\n  content: '';\n  position: absolute;\n  top: 10px;\n  right: 0;\n  width: 11px;\n  height: 11px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n}\n", ""]);
// Exports
module.exports = exports;
